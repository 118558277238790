const objectFitImages = require("object-fit-images");

exports.onInitialClientRender = () => {
  objectFitImages();
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname);
  console.log("old pathname", prevLocation ? prevLocation.pathname : null);
};
